<template>
    <q-page class="q-pa-md">
        <div class="row" v-if="!loading">
            <!-- Left Panel (Student) -->
            <div class="q-pa-md col-8">
                <q-card class="" style="max-width: 500px">
                    <q-list>
                        <q-item clickable>
                            <q-item-section avatar>
                                <q-icon color="primary" name="person" />
                            </q-item-section>

                            <q-item-section>
                                <q-item-label caption>Prénom</q-item-label>
                                <q-item-label>{{ student.first_name }}</q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item clickable>
                            <q-item-section avatar>
                                <q-icon color="red" name="face" />
                            </q-item-section>

                            <q-item-section>
                                <q-item-label caption>Nom</q-item-label>
                                <q-item-label>{{ student.last_name }}</q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item clickable>
                            <q-item-section avatar>
                                <q-icon color="amber" name="alternate_email" />
                            </q-item-section>

                            <q-item-section>
                                <q-item-label caption>E-Mail</q-item-label>
                                <q-item-label>{{ student.email ? student.email : 'Email non renseigné' }}</q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item clickable>
                            <q-item-section avatar>
                                <q-icon v-if="student.sexe === 'M'" name="male" color="blue" />
                                <q-icon v-else-if="student.sexe === 'F'" name="female" color="pink" />
                                <q-icon v-else name="transgender" />
                            </q-item-section>

                            <q-item-section>
                                <q-item-label caption>Sexe</q-item-label>
                                <q-item-label>
                                    {{ student.sexe ? (student.sexe === 'M' ? 'Homme' : 'Femme') : 'Sexe non renseigné' }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item clickable>
                            <q-item-section avatar>
                                <q-icon color="green" name="badge" />
                            </q-item-section>

                            <q-item-section>
                                <q-item-label caption>Matricule</q-item-label>
                                <q-item-label>{{ student.student_number }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        
                        <q-item clickable>
                            <q-item-section avatar>
                                <q-icon color="blue" name="date_range" />
                            </q-item-section>

                            <q-item-section>
                                <q-item-label caption>Création le</q-item-label>
                                <q-item-label>{{ student.created_at }}</q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item clickable>
                            <q-item-section avatar>
                                <q-icon color="blue" name="update" />
                            </q-item-section>

                            <q-item-section>
                                <q-item-label caption>Modifié le</q-item-label>
                                <q-item-label>{{ student.updated_at }}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-card>
            </div>
            <!-- Right panel (Responsibles & GED Button) -->
            <div class="q-pa-md col flex-center text-center">
                <q-card class="">
                    <q-card-section>
                        <div class="text-h6">Responsables</div>
                        <div class="text-subtitle2">de {{ student.first_name }} {{ student.last_name }}</div>
                    </q-card-section>

                    <q-tabs v-model="responsible_tab" class="text-teal">
                        <q-tab v-for="(r, idx) in student.responsibles" v-bind:key="r.id" :label="r.first_name + ' ' + r.last_name" :name="idx" />
                    </q-tabs>

                    <q-separator />

                    <q-tab-panels v-model="responsible_tab" animated>
                        <q-tab-panel v-for="(r, idx) in student.responsibles" v-bind:key="r.id" :name="idx">
                            <p>
                                Prénom : <em><b>{{ r.first_name }}</b></em><br />
                                Nom : <em><b>{{ r.last_name }}</b></em><br />
                                E-Mail : <em><b>{{ r.email ? r.email : 'Email non renseigné' }}</b></em><br />
                                Sexe : <q-icon v-if="r.sexe === 'M'" name="male" color="blue" /><q-icon v-else-if="r.sexe === 'F'" name="female" color="pink" /><q-icon v-else name="transgender" /><br /><br />
                                <q-select outlined v-model="r.type" :display-value="`${r.type === 'legal_responsible' ? 'Responsable légal' : 'Responsable financier'}`" :options="[{label: 'Responsable légal', value: 'legal_responsible'}, {label: 'Responsable financier', value: 'financial_responsible'}]" label="Type" />
                            </p>
                        </q-tab-panel>
                    </q-tab-panels>
                </q-card>
                <br /><br />
                <q-btn color="blue" label="Accéder aux documents GED" @click="gotoDocuments()" />
            </div>
        </div>
    </q-page>
</template>

<style>
</style>

<script>
import { useQuery, useResult } from '@vue/apollo-composable'
import { useRoute, useRouter } from 'vue-router'
import { ref } from 'vue'

export default {
    name: 'StudentDetails',
    methods: {
        // Go to GED page with matricule parameter
        gotoDocuments() {
            this.router.push({ name: 'GED', params: { matricule: this.student.student_number } })
        }
    },
    setup () {
        // Get route & router (router use in gotoDocuments)
        const route = useRoute()
        const router = useRouter()

        // Get Student data
        // Apollo request to Hasura
        const { result, loading } = useQuery(require('@/gql/students/GetStudent.gql'), {
                id: route.params.id
        })

        // Format result
        const student = useResult(result, null, data => {
            return {
                id: data.persons[0].id,
                first_name: data.persons[0].first_name,
                middle_name: data.persons[0].middle_name,
                last_name: data.persons[0].last_name,
                sexe: data.persons[0].sexe,
                email: data.persons[0].emails[0]?.email,
                student_number: data.persons[0].student_informations[0]?.student_number,
                responsibles: data.persons_responsibles.map(r => ({
                    id: r.responsible.id,
                    first_name: r.responsible.first_name,
                    last_name: r.responsible.last_name,
                    sexe: r.responsible.sexe,
                    email: r.responsible.emails[0]?.email,
                    type: r.responsible_types.id
                })),
                // Format to YYYY-MM-DD HH:mm:ss
                created_at: data.persons[0].created_at.split('T')[0] + ' ' + data.persons[0].created_at.split('T')[1].split('.')[0],
                updated_at: data.persons[0].updated_at.split('T')[0] + ' ' + data.persons[0].created_at.split('T')[1].split('.')[0]
            }
        })

        // Return data
        return {
            student,
            loading,
            responsible_tab: ref(0),
            router,
        }
    }
}
</script>

